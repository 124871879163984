import http from "../http-common";

class AuthService {
    async login(username: string, password: string) {
        await http.post(`/Auth/login`, {
            username,
            password
        }, { headers: { "Content-type": "application/json" } }).then(response => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        });

    }

    logout() {
        localStorage.removeItem('user');
    }

    async register(firstname: string, lastname: string, email: string, password: string) {
        console.log("register");
        await http.post(`/Auth/register`, {
            firstname,
            lastname,
            email,
            password
        },
            { headers: { "Content-type": "application/json" } }).then(response => {

                return response.data;
            });
    }
}

export default new AuthService();