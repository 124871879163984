















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from "vuex-class";
import http from "./http-common";
const Auth = namespace("Auth");

const storedUser = localStorage.getItem('user');
var user = storedUser ? JSON.parse(storedUser) : null;

@Component({
    components: {

    }
})
export default class App extends Vue {

    @Auth.State("user")
    private currentUser!: any;

    @Auth.Action
    public signOut!: () => void;

    //sideBarHide: Boolean = false;
    private pageTitle: String = "";
    private collapsed: Boolean = true;
    private menu: Array<any> = [
        {
            href: "/",
            title: "Home",
            icon: {
                element: 'b-icon',
                attributes: { icon: 'house' }
                // text: ''
            }
        },

        {
            title: "Canzoneniere",
            icon: {
                element: 'b-icon',
                attributes: { icon: 'music-note-beamed' }
                // text: ''
            },
            child: [
                {
                    href: "/ricercacanzone",
                    title: "Elenco Canzoni",
                    icon: {
                        element: 'b-icon',
                        attributes: { icon: 'music-note-list' }
                        // text: ''
                    }
                },
                {
                    href: "/creacanzone",
                    title: "Nuova Canzone",
                    icon: {
                        element: 'b-icon',
                        attributes: { icon: 'music-note' }
                        // text: ''
                    }
                }
            ]

        },
        {
            title: "Configuratore Liturgia",
            icon: {
                element: 'b-icon',
                attributes: { icon: 'book' }
                // text: ''
            },
            child: [{
                href: "/ricercaconfigurazioneliturgia",
                title: "Elenco Configurazioni Liturgia",
                icon: {
                    element: 'b-icon',
                    attributes: { icon: 'list' }
                }
            },
            {
                href: "/configuratoreliturgia",
                title: "Nuova Configurazione Liturgia",
                icon: {
                    element: 'b-icon',
                    attributes: { icon: 'card-checklist' }
                    // text: ''
                }
            }
            ]

        },



    ]

    private show: Boolean = false;

    onShowMenu() {
        this.show = true;
    }

    onHideMenu() {
        this.show = false;
    }

    onItemClick(e: any, i: any) {
        //console.log("onItemClick");
    }

    onCollapse(c: boolean) {
        //console.log("onCollapse");
        this.collapsed = c;
    }

    logOut() {
        this.signOut();
        this.$router.push("/login");
    }

    currentRouteName() {
        return this.$route.name;
        console.log(this.$route.name)
    }

    mounted() {
        // aggiorno il titolo di pagina
        document.title = (this.$route.meta.title != null && this.$route.meta.title != 'undefined') ? this.$route.meta.title : "Voglia Di Cantare";

        http.interceptors.response.use(
            (res) => {
                return res;
            },
            async (err) => {
                const originalConfig = err.config;
                if (originalConfig.url !== "/auth/login" && err.response) {
                    // Access Token was expired
                    if (err.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        try {
                            debugger;
                            const rs = await http.post("/auth/refresh", {
                                accessToken: user.accessToken,
                                refreshToken: user.refreshToken
                            }, { headers: { "Content-type": "application/json" } });
                            const { accessToken } = rs.data;
                            user.accessToken = accessToken;
                            localStorage.setItem("user", JSON.stringify(user));
                            return http(originalConfig);
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    }
                }
                return Promise.reject(err);
            }
        );
    }
}
