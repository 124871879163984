import Vue from "vue"
import VueRouter from "vue-router"

import Home from "./components/Home.vue"
import Login from "./components/Login.vue"
import Register from "./components/Register.vue"
//import CreaCanzone from "./components/CreaCanzone.vue"
//import ModificaCanzone from "./components/ModificaCanzone.vue"
//import DettaglioCanzone from "./components/DettaglioCanzone.vue"
//import RicercaCanzone from "./components/RicercaCanzone.vue"

const CreaCanzoneLazy = () => import('./components/CreaCanzone.vue')
const ModificaCanzoneLazy = () => import('./components/ModificaCanzone.vue')
const DettaglioCanzoneLazy = () => import('./components/DettaglioCanzone.vue')
const RicercaCanzoneLazy = () => import('./components/RicercaCanzone.vue')
const ProfileLazy = () => import('./components/Profile.vue')
const ConfiguratoreLiturgiaLazy = () => import('./components/ConfiguratoreLiturgia.vue')
const RicercaConfigurazioneLiturgiaLazy = () => import('./components/RicercaConfigurazioneLiturgia.vue')
const ElencoTestoConAccordiCanzoniConfigurazioneLiturgicaLazy = () => import('./components/ElencoTestoConAccordiCanzoniConfigurazioneLiturgica.vue')


Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history', 

    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: { title: 'Home - Voglia Di Cantare' }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: { title: 'Login - Voglia Di Cantare' }
        },
        {
            path: '/Register',
            name: 'register',
            component: Register,
            meta: { title: 'Register - Voglia Di Cantare' }
        },
        {
            path: '/profile',
            name: 'profile',
            component: ProfileLazy,
            meta: { title: 'Profilo - Voglia Di Cantare' }
        },
        {
            path: '/creacanzone',
            name: 'creacanzone',
            component: CreaCanzoneLazy,
            meta: { title: 'Crea nuova canzone - Voglia Di Cantare' }
        },
        {
            path: '/dettagliocanzone/:id',
            name: 'dettagliocanzone',
            component: DettaglioCanzoneLazy,
            meta: { title: 'Dettaglio canzone - Voglia Di Cantare' },
        },
        {
            path: '/modificacanzone/:id',
            name: 'modificacanzone',
            component: ModificaCanzoneLazy,
            meta: { title: 'Modifica canzone - Voglia Di Cantare' }
        },       
        {
            path: '/ricercacanzone',
            name: 'ricercacanzone',
            component: RicercaCanzoneLazy,
            meta: { title: 'Ricerca canzone - Voglia Di Cantare' }
        },
        {
            path: '/configuratoreliturgia/:id?',
            name: 'configuratoreliturgia',
            component: ConfiguratoreLiturgiaLazy,
            meta: { title: 'Configuratore Liturgia - Voglia Di Cantare' }
        },
        {
            path: '/ricercaconfigurazioneliturgia',
            name: 'ricercaconfigurazioneliturgia',
            component: RicercaConfigurazioneLiturgiaLazy,
            meta: { title: 'Ricerca Configurazione Liturgia - Voglia Di Cantare' }
        },
        {
            path: '/elencocanzoniconfigurazioneliturgica/:id',
            name: 'elencocanzoniconfigurazioneliturgica',
            component: ElencoTestoConAccordiCanzoniConfigurazioneLiturgicaLazy,
            meta: { title: 'Elenco Canzoni Configurazione Liturgica - Voglia Di Cantare' }
        },
    ]

})


