export default class User {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    constructor(_firstname: string, _lastname: string, _email: string, _password: string) {
        this.firstname = _firstname;
        this.lastname = _lastname;
        this.email = _email;
        this.password = _password;
    }
}