import axios from "axios";

export default axios.create({
    //dev
    //baseURL: "https://localhost:44343/api",
    //prod
    baseURL: "https://www.vogliadicantare.it/api",
    headers: {
        "Content-Type": "multipart/form-data"
    }
});