
































































































import { Component, Vue } from "vue-property-decorator";
import User from '../model/User';
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Register extends Vue {
    private loading: boolean = false;
    private successful: boolean = false;
    private submitted: boolean = false;
    private message: string = "";
    private user: User = new User("", "", "", "");
    // Initial data can be declared as instance properties
    private pageTitle: string = 'Register';

    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Action
    private register!: (data: any) => Promise<any>;

    created() {
        //console.log(this.isLoggedIn);
        if (this.isLoggedIn) {

            this.$router.push("/");
        }
        // nascondo la scrollbar
        //this.$emit('sideBarHide', false);

        // set page title
        //document.title = "Login";
        //this.$emit('pageTitle', 'Login');

    }

    handleRegister() {
        this.message = '';
        this.submitted = true;
        this.loading = true;
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loading = false;
                return;
            }
            if (this.user.firstname && this.user.lastname && this.user.email && this.user.password) {
                this.register(this.user).then(
                    (data) => {
                        this.message = 'Utente registrato con successo';
                        this.successful = true;
                        this.loading = false;
                    },
                    (error) => {
                        this.loading = false;
                        this.message = error;
                    }
                );
            }
        });
    }
}
