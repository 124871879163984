



































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Home extends Vue {
    @Prop() private msg!: string;
    // Initial data can be declared as instance properties
    pageTitle: string = 'VOGLIA DI CANTARE'
}
